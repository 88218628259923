
.launching-pad {

	height: 100%;
	width: 100%;

	background-color: #adcdce;

	background-image: url('./paper-house.jpg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	.navigation-row {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
	}
}
