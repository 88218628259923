.landing-pad {

	height: 100%;
	width: 100%;
	background: #6f7466;

	.slice {
		width: 100%;
		height: calc(100%/3);
	}

	.align-center {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.chevron-down {
		position: relative;
		top: 0px;

		transition-property: top;
		transition-duration: 0.7s;

		&:hover, &:active {
			top: 20px;
		}
	}

	.name-expander {

		@media screen and (max-width: 62ch) {
			font-size: 9vw;
		}

		font-size: 3em;


		font-family: monospace, monospace;

		white-space: nowrap;

		span {
			vertical-align: middle;
		}

		.text-expand {
			display: inline-block;
			overflow: hidden;

			color: #333333;
			font-weight: normal;

			animation-name: textExpand;
			animation-duration: 1.25s;
			animation-delay: 1s;
			animation-timing-function: ease-in-out;
			animation-fill-mode: both;
		}

		.text-static {
			color: black;
			font-weight: normal;
		}
	}
}

@keyframes textExpand {
	0% {width: 0; margin-left: 0;}
	25% {width: 0; margin-left: -15px;}
	45% {margin-left: 0;}
	100% {width: 7ch;}
}
