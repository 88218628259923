
.tabs {
	width: 100%;
	height: 100%;

	.tab {
		width: 100%;
		height: 100%;

		box-sizing: border-box;
		padding: 20px;
	}

	display: flex;
	flex-direction: column;

	.tabs-headers {
		flex-grow: 0;
		flex-shrink: 0;

		min-height: 60px;
		width: 100%;

		overflow: hidden;

		background-color: inherit;

		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-end;
	}

	.tabs-header {
		display: inline-block;

		&.active {
			z-index: 10;
		}

		// width starts at 25ch, and then shrinks as necessary to fit all tabs
		max-width: 25ch;
		width: 100%;

		height: 0;
		border-left: rgba(0,0,0,0) solid 22.5px;
		border-right: rgba(0,0,0,0) solid 22.5px;
		border-bottom: solid 45px;

		&:not(:first-child){
			margin-left: -2ch;
		}

		&:nth-child(odd){
			border-bottom-color: #ffe9c4;
		}

		&:nth-child(even){
			border-bottom-color: #E6DD93;
		}

		.tabs-header-content {
			height: 45px;
			padding-left: 2ch;
			padding-right: 2ch;

			overflow: hidden;

			cursor: pointer;

			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
		}
	}

	.tabs-content {
		flex-grow: 1;
		flex-shrink: 1;

		width: 100%;

		position: relative;

		.tabs-content-wrapper:nth-child(odd){
			background-color: #ffe9c4;
		}

		.tabs-content-wrapper:nth-child(even){
			background-color: #E6DD93;
		}

		.tabs-content-wrapper {
			position: absolute;
			width: 100%;
			height: 100%;
			overflow: auto;
		}
	}
}
