
.lights-out {
	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: aliceblue; // cadetblue;

	iframe {
		border: 0;
		display: block;
		width: 100%;
		height: 100%;
	}

	.discussion-tab {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
	}
}
