
.sidebar {
	position: relative;
	height: 100%;
	width: unset;

	z-index: 20;

	.sidebar-screen {
		position: absolute;
		width: 100%;
		height: 100%;

		background-color: black;
		opacity: 0.3;

		cursor: pointer;
	}

	.toggler {
		position: absolute;

		display: none;
		vertical-align: top;

		border-radius: 7px;
		margin: 20px;

		button {
			background-color: rgba(0,0,0,0); // transparent background

			color: grey;
		}
	}

	.sidebar-slider {
		position: relative;
		display: inline-block;
		vertical-align: top;
		font-size: 1.5em;

		height: 100%;
		width: 30ch;
		max-width: 100vw;

		box-sizing: border-box;

		flex-grow: 0;
		flex-shrink: 0;

		overflow-y: auto;

		background-color: #333;

		padding: 20px;

		.topic {
			width: 100%;
			box-sizing: border-box;

			background-color: darkseagreen;

			padding: 5px;
			margin-bottom: 5px;

			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	& ~ .sidebar-blur {
		filter: blur(0px);
	}

	// decides whether the sidebar will overlay on top,
	// or whether it will insert iself into the content flow
	@media screen and (max-width: 1250px) {
		position: absolute;
		width: 0%;

		&.expanded {
			width: 100%;
		}

		&.expanded ~ .sidebar-blur {
			filter: blur(5px);
		}

		&:not(.expanded) .sidebar-slider {
			width: 0;
			padding: 0;
			overflow: hidden;
		}

		&:not(.expanded) .sidebar-screen {
			display: none;
		}

		.toggler {
			display: inline-block;
		}

	}

	// decides whether the sidebar will snap to small screen width
	@media screen and (max-width: calc(1.5*30ch + 4*20px + 50px)) {
		&.expanded .toggler {
			right: 0;
		}
		&.expanded .sidebar-slider {
			width: 100%;
		}
	}
}
