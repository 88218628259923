
.navbar {

	width: 100%;
	background-color: lightgrey;

	font-size: 1em;

	display: flex;
	flex-wrap: wrap;

	justify-content: center;

	.navlink {
		flex-grow: 0;
		flex-shrink: 0;
	}

	.navlink {
		border: 1px solid black;
	}

	.navlink:not(:last-child){
		border-right-width: 0;
	}

	// decides whether the navbar buttons will stack
	@media screen and (max-width: calc(23ch*3 + 11ch)) {
		&.navbuttons .navlink {
			width: 100%;
		}
	}
}
