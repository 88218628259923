
.app {
	height: 100%;
	width: 100%;

	display: flex;
	flex-direction: column;

	.static-flex {
		width: 100%;
		flex-grow: 0;
		flex-shrink: 0;
	}
	.dynamic-flex {
		width: 100%;
		flex-grow: 1;
		flex-shrink: 1;
		overflow: auto;
		background-color: aliceblue;
	}

	button {
		border: none; // naked button
		font: inherit; // naked button

		cursor: pointer;
	}

	.hide {
		display: none;
	}

	.text-column {
		padding-left: 5ch;
		padding-right: 5ch;
		width: 60ch;
		max-width: 100%;
		box-sizing: border-box;
		text-align: justify;
	}
}
