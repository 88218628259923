.sketch {
	width: 100%;
	height: 100%;
	background-color: darkslategrey;

	display: flex;
	justify-content: center;
	align-items: center;

	overflow: hidden;

	position: relative;
	.floating-label1, .floating-label2 {
		position: absolute;
		font-size: 8em;
		color: #111111;
	}
	.floating-label1 {
		top: 0;
		left: 0;
	}
	.floating-label2 {
		bottom: 0;
		right: 0;
	}

	.overlay-container {
		flex-shrink: 0;

		position: relative;
		.overlay {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}

	line {
		stroke: white;
	}
}
