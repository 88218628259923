
.page-not-found {

	height: 100%;
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

}
