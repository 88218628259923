
.navbuttons {
	.navlink {
		width: 23ch;

		padding: 1.5ch;

		background-color: lightgrey;

		display: inline-flex;
		justify-content: center;
		align-items: center;

		box-sizing: border-box;

		font-weight: bold;
	}

	.buffered-button {
		margin: 1.5ch;
	}

	.home-button:hover, .home-button.active {
		color: white;
		transition: color 0.4s;
		background: linear-gradient(45deg, lightgrey, black);
	}

	.about-button:hover, .about-button.active {
		color: white;
		transition: color 0.4s;
		background: linear-gradient(45deg, teal, darkblue);
	}

	.experiments-button:hover, .experiments-button.active {
		color: white;
		transition: color 0.4s;
		background: linear-gradient(45deg, orange, red);
	}

	.resume-button:hover, .resume-button.active {
		color: white;
		transition: color 0.4s;
		background: linear-gradient(45deg, darkcyan, darkgreen);
	}
}
